// Here you can add other styles
.login-bg-image {
  background-image: url('../assets/brand//salt-creek-logo.png');
  background-size: cover;
  background-position: center;
  padding: 3.5em;
  color: white; /* Adjust text color for readability */
}

.table .table-primary-base {
  background-color: #032032;
  color: #ffffff;
}
.custom-card-color {
  background-color: #39d790 !important;
  color: #ffffff;
}
.custom-error {
  color: red; /* Set the text color to red */
  font-size: 14px; /* Adjust the font size */
  margin-top: 5px; /* Add some top margin to separate it from the input */
}
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.page-number {
  margin: 0 0px;
  cursor: pointer;
}

.page-number.active {
  font-weight: bold;
}

.form-control:focus {
  color: var(--cui-input-focus-color, #000000);
  background-color: var(--cui-input-focus-bg, #ffffff);
  border-color: var(--cui-input-focus-border-color, #274676);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 183, 255, 0.25);
}

// checkbox tree

.checkbox-container {
  margin-left: 17px;
}

.custom-checkbox {
  margin-right: 8px;
  accent-color: #274676;
  border-color: var(#274676);
  height: 16px; /* not needed */
  width: 16px; /* not needed */
}

.col > .react-datepicker-wrapper {
  width: 100%;
}

// /* Ensure the editor container maintains its size */
.rdg-editor-container {
  align-items: center;
  background-color: #0000;
  border: none;
  /* box-sizing: border-box; */
  /* display: flex; */
  height: 33px;
  min-width: -webkit-fill-available;
  padding: 0px;
  width: auto;
}

/* Optional focus style */
.rdg-editor-container:focus-within {
  background-color: #f0f0f0; /* Slight background change on focus */
}


// this changes are not using right now
/* Style for the input to make it look seamless  */
.inline-editor {
  width: 100%;
  height: fit-content;
  min-height: 30px; /* Prevent the cell from collapsing */
  padding: 0 5px;
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  font-size: inherit;
  outline: none;
}

.inline-editor:focus {
  outline: none; /* Prevent any outline when focused */
  background-color: transparent;
}


